import { RecursivePartial } from 'types';
import { createQRCodeOptionsDTO } from './createQRCodeOptionsDTO';
import templateLibrary from './templateLibrary';
import { QRCodeDTO } from '../types';

type QRCodeDTOEntry = Partial<Omit<QRCodeDTO, 'content' | 'qrDesign'>> &
  RecursivePartial<Pick<QRCodeDTO, 'content' | 'qrDesign'>>;

export const createQRCodeDTO = (entry: QRCodeDTOEntry): QRCodeDTO => {
  const templateFactory = entry.content && templateLibrary[entry.content.type || 'default'];
  const content = templateFactory ? templateFactory({ ...(entry.content || {}), name: entry?.name }) : null;
  return {
    id: entry.id || '',
    createdAt: entry?.createdAt || '',
    updatedAt: entry?.updatedAt || '',
    name: entry.name || '',
    userLanguage: entry.userLanguage || '',
    uri: entry.uri || '',
    content,
    qrDesign: createQRCodeOptionsDTO(entry?.qrDesign),
    disabled: entry.disabled || false,
    scansAmount: entry.scansAmount || 0,
    userSettingsJson: entry.userSettingsJson || {},
    malwareStatsJson: entry.malwareStatsJson || {},
  };
};
