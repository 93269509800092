/* eslint-disable consistent-return */
/* eslint-disable no-console */
import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import { showNotification } from 'config/toast';
import { getValidErrorMessage } from 'utils';
import { retry } from 'utils/retry';
import { userSettingsApi } from 'api/userSettings';
import { DEFAULT_LOCALE } from 'constants/languages';
import { getCookie, setAuthCookie } from 'utils/api';
import { LoginData, SocialSubmitPayload, User, UserCredential } from '../../types/auth';
import { authApi } from '../../api/auth';
import { localStorageService } from '../../libs/localStorage/local.storage.service';
import { setUser, removeUser, updateLangAndRegion, setIsAuthInProgress } from './index';
import { axiosApiInstance } from '../../api/axios';
import i18n, { changeLanguage } from 'config/i18n';

const handleSignUp = async (
  data: LoginData,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  onUserSet?: () => void
) => {
  const language = getCookie('lang') || i18n.language || navigator.language.split('-')[0] || navigator.language;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  dispatch(setUser(data.user));
  onUserSet?.();
  setAuthCookie(data.accessToken, data.user.role);

  await dispatch(setIsAuthInProgress(false));

  await userSettingsApi.changeTimeZone(timezone);
  await userSettingsApi.changeLanguage(language).catch(() => {
    userSettingsApi.changeLanguage(DEFAULT_LOCALE);
  });

  dispatch(updateLangAndRegion({ language, timezone }));
};

export const signUp = createAsyncThunk(
  'auth/signUp',
  async ({ credentials, onUserSet }: { credentials: UserCredential; onUserSet?: () => void }, { dispatch }) => {
    try {
      const { data } = await authApi.signUp(credentials);
      await handleSignUp(data, dispatch, onUserSet);
    } catch (error) {
      showNotification(getValidErrorMessage(error), 'error', () => {}, 'sign-in-error');
    }
  }
);

export const signUpSocial = createAsyncThunk(
  'auth/signUpSocial',
  async ({ type, token, signup, onUserSet }: SocialSubmitPayload & { onUserSet?: () => void }, { dispatch }) => {
    try {
      if (type === 'google') {
        const { data } = await authApi.googleSignUp(token);
        await handleSignUp(data, dispatch, onUserSet);
      } else if (type === 'facebook') {
        const { data } = await authApi.facebooSignUp(token);
        await handleSignUp(data, dispatch, onUserSet);
      } else if (type === 'apple') {
        if (signup) {
          const { data } = await authApi.appleSignup(token);
          await handleSignUp(data, dispatch, onUserSet);
        } else {
          const { data } = await authApi.appleSignupFinalize(token);
          await handleSignUp(data, dispatch, onUserSet);
        }
      }
    } catch (error) {
      console.error(error);
      showNotification(getValidErrorMessage(error), 'error', () => {}, 'sign-in-social-error');
    }

    return null;
  }
);

export const updateUser = createAsyncThunk('auth/updateUser', async (_, { dispatch }) => {
  try {
    const user = await authApi.getUser();

    dispatch(setUser(user));

    if (user) {
      document.cookie = `lang=${user.language ?? DEFAULT_LOCALE}; domain=${window.location.hostname}; path=/`;
    }
    return user;
  } catch (error) {
    console.error(error);
    showNotification(getValidErrorMessage(error), 'error');
    return error;
  }
});

export const logout = createAsyncThunk('auth/logout', async (_, { dispatch }) => {
  try {
    await retry(authApi.logout);

    dispatch(removeUser());
    setAuthCookie('', '');
  } catch (error) {
    console.error(error);
    return error;
  } finally {
    // Remove local data
    dispatch(removeUser());
    setAuthCookie('', '');
  }
});

export const refreshToken = createAsyncThunk('auth/refresh', async (ignoreErrors: boolean, { dispatch }) => {
  try {
    const { data } = await authApi.refreshToken({
      ...localStorageService.getTokens(),
      user: localStorageService.getUser() as User,
    });
    axiosApiInstance.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
    dispatch(setUser(data.user));

    setAuthCookie(data.accessToken, data.user.role);
    await changeLanguage(data.user.language);

    return data;
  } catch (error) {
    // logout
    setAuthCookie('', '');
    dispatch(removeUser());
    if (!ignoreErrors) {
      showNotification(getValidErrorMessage(error), 'error');
      console.error(error);
    }
  }
});
