import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { axiosApiInstance } from 'api/axios';
import { DefaultApiResponse } from 'types';
import { join } from 'utils';
import { ROUTES } from 'constants/api.routes';
import { createQRCodeDTO } from '../entities';

import { QRCodeDTO, APICreatePreviewQRCodeResponse } from '../types';

class QRCodeApi {
  public async getMany(): Promise<QRCodeDTO[]> {
    const { data }: AxiosResponse<QRCodeDTO[]> = await axiosApiInstance.get(ROUTES.QR_CODES_API.BASE);

    if (!data) return [];

    return data.map((item) => createQRCodeDTO(item)).filter(Boolean);
  }

  public async getOne(id: string): Promise<QRCodeDTO> {
    const url = join(ROUTES.QR_CODES_API.BASE, '/', id);

    const { data }: AxiosResponse<QRCodeDTO> = await axiosApiInstance.get(url);

    return createQRCodeDTO(data);
  }

  public async create(entry: QRCodeDTO): Promise<APICreatePreviewQRCodeResponse> {
    const { name, content } = entry;
    const { name: _, ...restContent } = content || {};

    const { data }: AxiosResponse<Partial<APICreatePreviewQRCodeResponse>> = await axiosApiInstance.post(
      ROUTES.QR_CODES_API.BASE,
      {
        name,
        content: restContent || null,
      }
    );

    return {
      id: data.id || '',
      uri: data.uri || '',
      validation: data.validation || {},
    };
  }

  public async update(
    id: string,
    entry: QRCodeDTO,
    shouldShowErrorNotification?: boolean
  ): Promise<
    AxiosResponse<
      DefaultApiResponse & {
        validation: {
          invalidUrls: string[];
        };
      }
    >
  > {
    const { name, content, qrDesign } = entry;
    const { name: _, ...restContent } = content || {};

    const url = join(ROUTES.QR_CODES_API.BASE, '/', id);

    return axiosApiInstance.request({
      method: 'put',
      url,
      data: {
        name,
        content: restContent || null,
        qrDesign,
      },
      shouldShowErrorNotification,
    } as AxiosRequestConfig);
  }

  public async savePreview(
    id: string,
    previewQRCodeId: string,
    entry: QRCodeDTO,
    shouldShowErrorNotification?: boolean
  ): Promise<DefaultApiResponse> {
    const { name, content, qrDesign } = entry;
    const { name: _, ...restContent } = content || {};

    const url = join(ROUTES.QR_CODES_API.BASE, '/publish-preview/', id);

    return axiosApiInstance.request({
      method: 'put',
      url,
      data: {
        qrCodeDto: {
          name,
          content: restContent || null,
          qrDesign,
        },
        previewQRCodeId,
      },
      shouldShowErrorNotification,
    } as AxiosRequestConfig);
  }

  public async publish(id: string): Promise<DefaultApiResponse> {
    const url = join(ROUTES.QR_CODES_API.BASE, '/publish/', id);

    return axiosApiInstance.put(url);
  }

  public async delete(ids: string[]): Promise<DefaultApiResponse> {
    return axiosApiInstance.delete(ROUTES.QR_CODES_API.BASE, {
      data: { ids },
    });
  }

  public async resetScans(ids: string[]): Promise<DefaultApiResponse> {
    return axiosApiInstance.post(ROUTES.QR_CODES_API.RESET_SCANS, { ids });
  }

  public async activate(ids: string[]): Promise<DefaultApiResponse> {
    return axiosApiInstance.post(ROUTES.QR_CODES_API.ACTIVATE, { ids });
  }

  public async deactivate(ids: string[]): Promise<DefaultApiResponse> {
    return axiosApiInstance.post(ROUTES.QR_CODES_API.DEACTIVATE, { ids });
  }

  public async getOneAsGuest(id: string): Promise<QRCodeDTO> {
    const url = join(ROUTES.QR_CODES_GUEST_API.BASE, '/', id);

    const { data }: AxiosResponse<QRCodeDTO> = await axiosApiInstance.get(url);

    return createQRCodeDTO(data);
  }

  public async createAsGuest(entry: QRCodeDTO): Promise<APICreatePreviewQRCodeResponse> {
    const { name, content } = entry;
    const { name: _, ...restContent } = content || {};

    const { data }: AxiosResponse<Partial<APICreatePreviewQRCodeResponse>> = await axiosApiInstance.post(
      ROUTES.QR_CODES_GUEST_API.BASE,
      {
        name,
        content: restContent || null,
      }
    );

    return {
      id: data.id || '',
      uri: data.uri || '',
      validation: data.validation || {},
    };
  }

  public async updateAsGuest(
    id: string,
    entry: QRCodeDTO,
    shouldShowErrorNotification?: boolean
  ): Promise<
    AxiosResponse<
      DefaultApiResponse & {
        validation: {
          invalidUrls: string[];
        };
      }
    >
  > {
    const { name, content, qrDesign } = entry;
    const { name: _, ...restContent } = content || {};

    const url = join(ROUTES.QR_CODES_GUEST_API.BASE, '/', id);

    return axiosApiInstance.request({
      method: 'put',
      url,
      data: {
        name,
        content: restContent || null,
        qrDesign,
      },
      shouldShowErrorNotification,
    } as AxiosRequestConfig);
  }
}

export const qrCodeApi = new QRCodeApi();
