import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  StatisticsScorecard,
  StatisticsDonutChartItem,
  StatisticsCity,
  StatisticsCountry,
  StatisticsOS,
  StatisticsChartsRequestParam,
  StatisticsFiltersOptionsResponse,
  StatisticsGeneral,
} from 'types/statistics';
import { subMonths } from 'date-fns';
import { getUTCDateTime } from 'utils';
import {
  fetchStatisticsScorecard,
  fetchCitiesStatistics,
  fetchCountriesStatistics,
  fetchOSStatistics,
  fetchGeneralStatistics,
  fetchStatisticsFilters,
} from './thunks';
import { translateCityName } from 'utils/localisation';
import i18next from 'i18next';

export interface StatisticsState {
  scorecard: StatisticsScorecard;
  donuts: {
    os: StatisticsDonutChartItem[];
    countries: StatisticsDonutChartItem[];
    cities: StatisticsDonutChartItem[];
  };
  filters: StatisticsChartsRequestParam;
  filtersOptions: StatisticsFiltersOptionsResponse;
  general: StatisticsGeneral[];
}

const today = getUTCDateTime(new Date());
// Define the initial state using that type
const initialState: StatisticsState = {
  scorecard: {
    totalQrCodes: 0,
    totalScans: 0,
    uniqueScans: 0,
  },
  donuts: { os: [], countries: [], cities: [] },
  filters: {
    from: getUTCDateTime(new Date(subMonths(today, 1))),
    to: today,
    os: [],
    countries: [],
    cities: [],
    qrCodeIds: [],
  },
  filtersOptions: { qrCodes: [], os: [], countries: [], cities: [] },
  general: [],
};

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<Partial<StatisticsChartsRequestParam>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (qrCodes) => {
    qrCodes.addCase(fetchStatisticsScorecard.fulfilled, (state, action) => {
      state.scorecard = action.payload;
    });
    qrCodes.addCase(fetchStatisticsScorecard.rejected, (state) => {
      state.scorecard = initialState.scorecard;
    });

    // Cities
    qrCodes.addCase(fetchCitiesStatistics.fulfilled, (state, action: PayloadAction<StatisticsCity[]>) => {
      const t = i18next.t;
      state.donuts.cities = action.payload.map(({ city, ...stat }) => ({
        ...stat,
        label: translateCityName(city, t),
      }));
    });
    qrCodes.addCase(fetchCitiesStatistics.rejected, (state) => {
      state.donuts.cities = initialState.donuts.cities;
    });

    // Countries
    qrCodes.addCase(fetchCountriesStatistics.fulfilled, (state, action: PayloadAction<StatisticsCountry[]>) => {
      state.donuts.countries = action.payload.map(({ country, ...stat }) => ({
        ...stat,
        label: country,
      }));
    });
    qrCodes.addCase(fetchCountriesStatistics.rejected, (state) => {
      state.donuts.countries = initialState.donuts.countries;
    });

    // OS
    qrCodes.addCase(fetchOSStatistics.fulfilled, (state, action: PayloadAction<StatisticsOS[]>) => {
      state.donuts.os = action.payload.map(({ os, ...stat }) => ({
        ...stat,
        label: os,
      }));
    });
    qrCodes.addCase(fetchOSStatistics.rejected, (state) => {
      state.donuts.os = initialState.donuts.os;
    });

    // Filters
    qrCodes.addCase(
      fetchStatisticsFilters.fulfilled,
      (state, action: PayloadAction<StatisticsFiltersOptionsResponse>) => {
        state.filtersOptions = action.payload;
      }
    );
    qrCodes.addCase(fetchStatisticsFilters.rejected, (state) => {
      state.filtersOptions = initialState.filtersOptions;
    });

    // General
    qrCodes.addCase(fetchGeneralStatistics.fulfilled, (state, action: PayloadAction<StatisticsGeneral[]>) => {
      state.general = action.payload;
    });
    qrCodes.addCase(fetchGeneralStatistics.rejected, (state) => {
      state.general = initialState.general;
    });
  },
});

export const { setFilters } = statisticsSlice.actions;

export default statisticsSlice.reducer;
