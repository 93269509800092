import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'types/auth';
import { localStorageService } from 'libs/localStorage/local.storage.service';

export type AuthState = {
  user: User | null;
  isAuthInProgress: boolean;
  isLoggedIn: boolean;
};

export const initialState: AuthState = {
  user: null,
  isAuthInProgress: false,
  isLoggedIn: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getUser: (state) => {
      const user = localStorageService.getUser();

      if (typeof user === 'string') return;

      state.user = user;
    },
    setIsAuthInProgress: (state, { payload }) => {
      state.isAuthInProgress = payload;
    },

    setUser: (state, { payload }: PayloadAction<User | null>) => {
      state.user = payload;
      state.isLoggedIn = !!payload;

      if (!payload) return;
      localStorageService.setUser(payload);
    },
    updateLangAndRegion: (state, { payload }) => {
      const updatedUser = {
        ...state.user,
        ...payload,
      };
      state.user = updatedUser;
      localStorageService.setUser(updatedUser);
    },
    removeUser: (state) => {
      state.user = null;
      state.isLoggedIn = false;
      localStorageService.removeUser();
      localStorageService.setScansLimitAlert(true);
    },
    updateUser: (state, { payload }: PayloadAction<User>) => {
      state.user = { ...state.user, ...payload };
      localStorageService.setUser(payload);
    },
  },
});

export const { setUser, removeUser, getUser, updateUser, setIsAuthInProgress, updateLangAndRegion } = authSlice.actions;

export default authSlice.reducer;
