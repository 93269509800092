/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { AuthState } from './index';

export const selectAuth = (state: RootState) => state.auth;

export const selectIsAuthInProgress = createSelector([selectAuth], (auth: AuthState) => auth.isAuthInProgress);

export const selectUser = createSelector([selectAuth], (auth: AuthState) => auth.user);

export const selectUserStatus = createSelector([selectAuth, selectUser], (_, user) => user?.status);
export const selectUserFreeScans = createSelector([selectAuth, selectUser], (_, user) => user?.freeScans);
